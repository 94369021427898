<template>
  <v-dialog
    :value="value"
    v-bind="$attrs"
    :width="'auto'"
    :fullscreen="$vuetify.breakpoint.mobile && !preventFullscreen"
    scrollable
    @input="$emit('input', $event)"
    v-on="$listeners"
  >
    <slot />
  </v-dialog>
</template>

<script>
export default {
  name: 'Popup',
  props: {
    value: {
      type: [Boolean],
      default: false
    },
    preventFullscreen: {
      type: Boolean,
      default: false
    }
  }
};
</script>
